<template>
   
    <div>
        <div class="tb">
            <i class="iconfont icon-fanhui"></i>
        </div>
        <div class="second_title_box">
            <span class="second_title1">
                优化我的信用
            </span>
        </div>
        <el-card class="a">
            <div class="heard">
                <div class="gdpgzx">
                    <span>更多评估咨询：</span>
                    <el-select v-model="value" placeholder="请选择" class="gdpgzx_select">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                       >
                        </el-option>
                    </el-select>
                </div>
                <div class="year">
                    <span style="maging-left:-100px">年份：</span>
                    <el-date-picker
                    class="date-picker-icon"
                    v-model="value1"
                    type="date"     
                    placeholder="选择日期"
                    prefix-icon="iconfont icon-riqi"
                    :editable="false"
                    >
                    </el-date-picker>
                </div>
                <div class="cgbutton">
                    <el-button type="success" round style="" class="anniu" @click="tijiao()">提交</el-button>
                </div>
            </div>
            <div class="dk2">
                <div class="dk3">
                    <div class="dk3_1">
                        <div class="from3">
                            <el-form :label-position="labelPosition"  :model="formLabelAlign" class="f">
                                <div class="fl">
                                <div class="topbutton">
                                    <el-form-item label="流动资产合计:">
                                        <el-input v-model="formLabelAlign.totalCurrentAssets" class="mc"></el-input>
                                    </el-form-item>
                                </div>
                                <!-- <div class="topbutton2">  -->
                                <el-form-item label="货币资金:">
                                    <el-input v-model="formLabelAlign.monetaryCapital" class="mc"></el-input>
                                </el-form-item>
                                <!-- </div> -->
                                <div class="topbutton2"> 
                                    <el-form-item label="应收账款:">
                                        <el-input v-model="formLabelAlign.accountsReceivable" class="mc"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="topbutton2"> 
                                    <el-form-item label="预计款项:">
                                        <el-input  class="mc" v-model="formLabelAlign.advancePayment"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="topbutton2"> 
                                    <el-form-item label="存货:">
                                        <el-input  class="mc" v-model="formLabelAlign.stock"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="topbutton2"> 
                                    <el-form-item label="待摊费用:">
                                        <el-input  class="mc" v-model="formLabelAlign.unamortizedExpense"></el-input>
                                    </el-form-item>
                                </div>
                                </div>
                            </el-form>
                        </div>
                        <div class="from3"> 
                            <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" class="f">
                                <div class="fl">
                                    <div class="topbutton">
                                        <el-form-item label="负债合计:">
                                            <el-input  class="mc"></el-input>
                                        </el-form-item>
                                    </div>
                                    <!-- <div class="topbutton2">  -->
                                        <el-form-item label="应付账款:">
                                            <el-input  class="mc"></el-input>
                                        </el-form-item>
                                    <!-- </div> -->
                                    <div class="topbutton2"> 
                                        <el-form-item label="预收账款:">
                                            <el-input  class="mc"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="topbutton2"> 
                                    <el-form-item label="应付工资:">
                                        <el-input  class="mc"></el-input>
                                    </el-form-item>
                                    </div>
                                    <div class="topbutton2"> 
                                    <el-form-item label="应付税金:">
                                        <el-input  class="mc"></el-input>
                                    </el-form-item>
                                    </div>
                                    <div class="topbutton2"> 
                                    <el-form-item label="其他应付款:">
                                        <el-input  class="mc"></el-input>
                                    </el-form-item>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                        <div class="from3"> 
                            
                            <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign" class="f3">
                                <div class="fl" >
                                <div class="topbutton">
                                <el-form-item label="非流动资产合计:" class="mgtop" id="bs">
                                    <el-input  class="mc3" v-model="formLabelAlign.totalNonCurrentAssets"></el-input>
                                </el-form-item>
                                </div>
                                <!-- <div class="topbutton2"> -->
                                <el-form-item label="长期投资:" id="bs">
                                    <el-input  class="mc3" v-model="formLabelAlign.permanentInvestment"></el-input>
                                </el-form-item>
                                <!-- </div> -->
                                <div class="topbutton2">
                                <el-form-item label="固定资产净值:" id="bs">
                                    <el-input  class="mc3" v-model="formLabelAlign.netValueOfFixedAssets"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="在建工程:" id="bs">
                                    <el-input  class="mc3" v-model="formLabelAlign.constructionInProcess"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="无形资产:" id="bs">
                                    <el-input  class="mc3" v-model="formLabelAlign.intangibleAssets"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="长期待摊费用:" id="bs">
                                    <el-input  class="mc3" v-model="formLabelAlign.longTermUnamortizedExpenses"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="递延税款借项:" id="bs">
                                    <el-input  class="mc3" v-model="formLabelAlign.deferredTaxesDebit"></el-input>
                                </el-form-item>
                                </div>
                                </div>
                            </el-form>
                        </div>
                        <div class="from3"> 
                            <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" class="f3_2">
                                <div class="fl">
                                <div class="topbutton">
                                <el-form-item label="股东权益合计:"  class="mgtop">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <!-- <div class="topbutton2"> -->
                                <el-form-item label="实收资本:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                <!-- </div> -->
                                <div class="topbutton2">
                                <el-form-item label="资本公积:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="盈余公积:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="未分配利润:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="资产总额:">
                                    <el-input class="mc"></el-input>
                                </el-form-item>
                                </div>
                                </div>
                            </el-form>
                            
                        </div>
                    </div>
                    <div class="dk3_2">
                        <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" class="f2">
                            <div class="fl">
                                <div class="topbutton">
                                <el-form-item label="主营业收入:"  >
                                    <el-input class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <!-- <div class="topbutton2"> -->
                                <el-form-item label="主营业成本:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                <!-- </div> -->
                                <div class="topbutton2">
                                <el-form-item label="主营业税金:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="主营业利润:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="其他业务成本:">
                                    <el-input class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="营业费用:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="管理费用:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="财务费用:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="营业利润:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="投资损益:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="营业外收入:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="利润总额:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="所得税:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                                <div class="topbutton2">
                                <el-form-item label="净利润:">
                                    <el-input  class="mc"></el-input>
                                </el-form-item>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </div>
                <div class="dk4">
                    <div class="dk4_1">
                        <el-form :label-position="labelPosition" label-width="110px"  class="f4"  >
                            <div class="f4_1">
                                <div class="ygrs" id="gs">
                                    <el-form-item label="员工人数:">
                                                <el-input  class="inputk" v-model="formLabelAlign.numberOfEmployees"></el-input>
                                    </el-form-item>
                                </div>
                                <div id="gs" style="float:left">
                                <el-form-item label="核心员工人数:" >
                                        <el-input   class="inputk" v-model="formLabelAlign.numberOfCoreEmployees"></el-input>
                                </el-form-item>
                                </div>
                                <div class="ygrs2" id="gs">
                                    <el-form-item label="主营业务:" :inline="true" style="flex-wrap:wrap" class="in">
                                        <el-input  class="inputk" v-model="formLabelAlign.deferredTaxesDebit"></el-input>
                                        <el-input  class="inputk2" v-model="formLabelAlign.deferredTaxesDebit"></el-input>
                                        <el-input   class="inputk2"  v-model="formLabelAlign.deferredTaxesDebit"></el-input>   
                                    </el-form-item>
                                </div>
                                <div class="ygrs2" id="gs">
                                    <el-form-item label="国际业务描述:" class="in">
                                        <el-select  placeholder="请选择" class="inputk" v-model="formLabelAlign.deferredTaxesDebit">
                                            <el-option
                                            >
                                            </el-option>
                                        </el-select>
                                        <el-input  class="inputk2" v-model="formLabelAlign.deferredTaxesDebit" ></el-input>
                                        <el-input  class="inputk3" v-model="formLabelAlign.deferredTaxesDebit" ></el-input> 
                                    </el-form-item>
                                </div>
                                <div class="ygrs3" id="gs">
                                    <el-form-item label="核心竞争力:" class="in">
                                        <el-checkbox-group 
                                           v-model="formLabelAlign.deferredTaxesDebit" > 
                                            <el-checkbox v-for="city in cities" fill="red" :label="city" :key="city" class="dxk" >{{city}}</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </div>
                                <div id="gs">
                                    <el-form-item label="业务展望:" >
                                        <el-select v-model="formLabelAlign.deferredTaxesDebit" placeholder="请选择" class="inputk" >
                                            <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </div>
                <div class="dk5">
                    <div class="dk5_1">
                        <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" class="f5"  >
                            <div class="c">
                                <div  class="qscwjzl">请上传文件资料</div>
                                <el-upload
                                class="upload-demoscwj"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :on-change="handleChange"
                                :file-list="fileList">
                                <div class="djsc2">
                                    <el-button size="small" type="primary" round class="djsc">上传文件</el-button>     
                                </div>
                                <div  slot="tip"  class="yscwj">已上传文件</div>
                                </el-upload>
                            </div>
                        </el-form>
                        
                    </div>
                </div>
            </div>   
        </el-card>
    </div>
    
</template>

<script>
const cityOptions = ['专利', '商标', '著作权', '软件著作权','高新'];
import {creditOptimization} from "@/api/supplier.js";
export default {
 data() {
      return {
        fileList: [{
          name: 'food.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }, {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
        , {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
        , {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
        , {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
        , {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }],
        name:"",
        value1: '',
        labelPosition: 'right',
        checkedCities: [],
        cities: cityOptions,
        formLabelAlign: {
          name: '',
          region: '',
          type: ''
        },
      };
    },
    methods:{
       handleChange(file, fileList) {
            console.log(file.name);
            console.log(this.fileList);
            console.log(fileList[0].name);
            console.log(fileList.name);
            this.name = file.name;
            console.log(this.name);
      },
     async tijiao(){
         await creditOptimization()
      }
    }
}
</script>

<style  scoped>


</style>