
import axios,{ apiUrl }  from "./request";
// const api = process.env.NODE_ENV == "development" ? '/devapi' : '/api'
// const api = process.env.NODE_ENV == "development" ? '/devapi' : 'https://www.o2c-scf.com/api'
const api = process.env.NODE_ENV == "development" ? '/devapi' : apiUrl()

// 我的工作台
export const supplier = (params) => {
  return axios.post(api + '/statistic/supplier?' + params)
}

// 我的融资柱状图
export const financingNumAmount = (params) => {
  return axios.post(api + '/statistic/supplier/financingNumAmount?' + params)
}

// 我的伙伴柱状图 伙伴评级
export const partnerCredit = (params) => {
  return axios.post(api + '/statistic/supplier/partnerCredit?' + params)
}

// 我的伙伴柱状图 交易金额
export const partnerDealAmount = (params) => {
  return axios.post(api + '/statistic/supplier/partnerDealAmount?' + params)
}

// 我的伙伴柱状图 交易金额占比
export const partnerAmountRatio = (params) => {
  return axios.post(api + '/statistic/supplier/partnerAmountRatio?' + params)
}

// 我的伙伴柱状图 交易地区统计
export const partnerDealRegion = (params) => {
  return axios.post(api + '/statistic/supplier/partnerDealRegion?' + params)
}

//查询伙伴
export const findCodeInfoByKey = (params) => {
  return axios.post(api + '/codeInfo/findCodeInfoByKey?' + params)
}

//查询伙伴
export const getCoreEnterpriseLists = (params) => {
  return axios.post(api + '/supplier/getCoreEnterpriseLists?' + params)
}

//查询发票表格
export const getInvoiceList = (params) => {
  return axios.post(api + '/invoice/getInvoiceList?' + params)
}

//发票页面银行接口
export const getUserInfos = (params) => {
  return axios.post(api + '/sys/getUserInfo?' + params)
}

//我的发票图表
export const getInvoiceNum = (params) => {
  return axios.post(api + '/invoice/getInvoiceNum?' + params)
}

//添加编辑发票
export const editInvoice = (params) => {
  return axios.post(api + '/invoice/editInvoice', params)
}

//删除发票
export const delInvoiceById = (params) => {
  return axios.post(api + '/invoice/delInvoiceById?' + params)
}

//申请融资
export const financing = (params) => {
  return axios.post(api + '/supplier/financing?', params)
}

//发票评级
export const invoiceGrade = (params) => {
  return axios.post(api + '/invoice/invoiceGrade?' + params)
}

//查询供应商名称
export const getSupplierName = (params) => {
  return axios.post(api + '/core/getSupplierName?' + params)
}

//查询供应商名称 new
export const CompanyNameList = (params) => {
  return axios.post(api + '/sys/CompanyNameList?' + params)
}



//查询我的伙伴
export const getMyPartnerList = (params) => {
  return axios.post(api + '/supplier/getMyPartnerList?' + params)
}

//获取供应商编号
export const mypartner = (params) => {
  return axios.post(api + '/supplier/mypartner?', params)
}

//线下邀约
export const offlineInvitation = (FN, params) => {
  return axios.post(api + '/supplier/offlineInvitation?FN=' + FN, params)
}

//获取放款详情
export const getDetailLoan = (params) => {
  return axios.post(api + '/supplier/getDetailLoan?' + params)
}

//确认放款详情
export const surePayment = (params) => {
  return axios.post(api + '/supplierloan/surePayment?' + params)
}

//查看申请融资列表
export const getSupplierList = (params) => {
  return axios.post(api + '/supplier/getSupplierList?' + params)
}

//选择资方
export const findAllMyManageByFinancingNum = (params) => {
  return axios.post(api + '/financingComparison/findAllMyManageByFinancingNum?' + params)
}

//撤销融资
export const revokeFinancing = (params) => {
  return axios.post(api + '/supplier/revokeFinancing?' + params)
}

//反馈提交
export const updateFinancingComparisonInfo = (params) => {
  return axios.post(api + '/financingComparison/updateFinancingComparisonInfo', params)
}

//接受邀约
export const acceptInvite = (params) => {
  return axios.post(api + '/supplier/acceptInvite?' + params)
}

//接受邀约
export const refuseInvite = (params) => {
  return axios.post(api + '/supplier/refuseInvite?' + params)
}
// 洽谈详情
export const getFinancingCompList = (params) => {
  return axios.post(api + '/financingComparison/getFinancingCompList?' + params)
}

// 洽谈详情new
export const uploadContractSupplier = (a, b, c, d, params) => {
  return axios.post(api + '/supplier/uploadContractSupplier?financing_num=' + a + '&&manager_id=' + b + '&&supplier_id=' + c + '&&codeFlag=' + d, params)
}
// export const uploadContractSupplier = (params)=>{
//     return axios.post(api + '/supplier/uploadContractSupplier?financing_num=1&&manager_id=1&&supplier_id=1' , params, { type: "upload" })
// }

// 下载合同
export const downloadContractSupplier = (params) => {
  return axios.post(api + '/supplier/downloadContractSupplier?' + params)
}
// 下载合同name
export const getContractSupplier = (params) => {
  return axios.post(api + '/supplier/getContractSupplier?' + params)
}

// 上传合同
export const contract = (params) => {
  return axios.post(api + '/financingComparison/contract?' + params)
}

// // 供应商融资列表
// export const getSupplierList = (params)=>{
//     return axios.post(api + '/supplier/getSupplierList?' + params)
// }

// creditInfo
export const creditInfo = (params) => {
  return axios.post(api + '/supplierCredit/creditInfo', params)
}

// operating
export const operating = (params) => {
  return axios.post(api + '/supplierCredit/operating', params)
}

// riskInfo
export const riskInfo = (params) => {
  return axios.post(api + '/supplierCredit/riskInfo', params)
}

// getUserInfo 
export const getUserInfoCredit = (params) => {
  return axios.post(api + '/sys/getUserInfoCredit?' + params)
}
// getUserInfo 
export const getUserInfo = (params) => {
  return axios.post(api + '/sys/getUserInfo?' + params)
}

// 我的信用 
export const ratingInfo = (params) => {
  return axios.post(api + '/supplierCredit/ratingInfo', params)
}

// 还款详情
export const showLoan = (params) => {
  return axios.post(api + '/supplierloan/showLoan?' + params)
}

//  供应商我的还款列表
export const getSupplierLoanList = (params) => {
  return axios.post(api + '/supplierloan/getSupplierLoanList?' + params)
}

// 还款确认
export const uploadSupplierLoan = (params) => {
  return axios.post(api + '/supplierloan/uploadSupplierLoan?', params)
}
// 还款详情
export const getFinancingComparisonById = (params) => {
  return axios.post(api + '/financingComparison/getFinancingComparisonById?' + params)
}
//还款详情(还款流水表格)
export const getRepaymentDetails = (params) => {
  return axios.post(api + '/supplierloan/searchLoanList?' + params)
}
// 还款图表
export const supplierLoanState = (params) => {
  return axios.post(api + '/supplierloan/supplierLoanState?' + params)
}

//上传
export const uploads = (params) => {
  return axios.post(api + '/upload/uploads', params, { type: "upload" })
}

//getSelectInvation
export const getSelectInvation = (params, cnname) => {
  return axios.post(api + '/supplier/getSelectInvation?' + params)
}
//查看我的服务列表
export const findSupplierMyServiceList = (params, a, b, c, d, e) => {
  return axios.post(api + '/supplier/findSupplierMyServiceList?' + params)
}

//申请服务
export const applyService = (params) => {
  return axios.post(api + '/service/applyService', params)
}
//查询服务商订单中心列表
export const getOrderList = (params, a, c, d, e, f, g, h, i) => {
  return axios.post(api + '/service/getOrderList?' + params, a, c, d, e, f, g)
}
//提交订单
export const submitOrder = (params) => {
  return axios.post(api + '/service/submitOrder', params)
}

//导出订单数据
export const ExportOrderList = (params, a, b, c, d, e, f) => {
  return axios.post(api + '/service/ExportOrderList?' + params, a, b, c, d, e, f)
}

//撤销
export const cancelOrder = (params) => {
  return axios.post(api + '/service/cancelOrder?' + params)
}
//优化信用列表
export const creditOptimizationList = (params, a) => {
  return axios.post(api + '/supplierCredit/creditOptimizationList?' + params, a)
}

//
export const creditOptimization = (params, data) => {
  return axios.post(api + `/supplierCredit/creditOptimization?entName=${params.entName}&year=${params.year}`, data)
}

// 风险预测
export const indexTool = (params) => {
  return axios.post(api + '/supplierCredit/indexTool?' + params)
}
